import * as React from 'react';
import Stateless from './index.stateless';
import useAuthState from '../../../state/auth';
import useDecksState from '../../../state/decks';
import { useNavigate, useParams } from 'react-router-dom';
import Decks from '../../../api/decks';
import toast from 'react-hot-toast';

const QuizletImport = () => {
  const auth = useAuthState();
  const decks = useDecksState();
  const params = useParams<{ id: string }>();
  const id = params.id!;
  const deck = decks.Decks.find((v) => v.id === id);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const [loadingDeck, setLoadingDeck] = React.useState(false);
  React.useEffect(() => {
    if (auth.User?.token && !deck) {
      setLoadingDeck(true);
      Decks.GetDecks(auth.User.token)
        .then((data) => decks.SetDecks(data))
        .finally(() => setLoadingDeck(false));
    }
  }, [auth.User?.token, deck]);

  const onFileSelected = async (f: File) => {
    setLoading(true);
    const uploadPromise = Decks.UploadQuizlet(auth.User?.token || '', id, f);

    toast.promise(uploadPromise, {
      loading: 'Uploading...',
      success: 'Imported.',
      error: 'Failed to import Quizlet deck. Try again later.',
    });
    await uploadPromise;
    const newCards = await Decks.GetCards(auth.User?.token || '', id);
    decks.SetCards(id, newCards);
    setLoading(false);
    navigate(`/dashboard/decks/${id}`);
  };

  const navigateSettings = () => navigate(`/dashboard/decks/${deck!.id}/settings`);

  return <Stateless deck={deck} loading={loading || loadingDeck} navigateSettings={navigateSettings} onFileSelected={onFileSelected} />;
};

export default QuizletImport;
