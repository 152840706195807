import styled from 'styled-components';
import { ISelectProps, InputBase } from '../TextInput';

const Select = styled.select<ISelectProps>`
  ${InputBase}
`;

export default Select;

export const Option = styled.option`
  padding: 8px;
`;
