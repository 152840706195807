import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { BaseButtonCSS } from '../../../components/Button';
import Header from '../../../components/Header';
import { Theme } from '../../../style';
import { IsMock } from '../../../utils/mock';
import Screenshot1 from '../../../../assets/screenshots/pomelo10.jpg?format=webp';
import Screenshot2 from '../../../../assets/screenshots/pomelo9.jpg?format=webp';
import TravelBackground from '../../../../assets/landing-pic-3.jpg?quality=85&format=webp';
import HomeHeroLogo from '../../../../assets/logo-src.png?format=webp';

const HomeHeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.accentForeground};
  height: 70vh;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    height: 90vh;
  }
`;

const HomeHeroContent = styled.div``;

const HomeHeroLogoContainer = styled.div``;
const HomeHeroLogoImage = styled.img`
  max-height: 150px;
  margin-top: 25px;
  margin-right: 75px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    margin-right: 25px;
    max-height: 75px;
    margin-top: 0;
    margin-bottom: 25px;
  }
`;

const HomeHero = styled.div`
  width: 1200px;
  max-width: 1200px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 30px;
  }
`;

const HomeHeroMainText = styled.h1`
  font-size: 10em;
  margin: 0;
  font-weight: normal;
  font-family: ${(props) => props.theme.fonts.header};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    font-size: 4em;
  }
`;

const HomeHeroSubtitle = styled.h2`
  font-size: 2em;
  margin: 0;
  font-weight: 200;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    font-size: 1.3em;
  }
`;

const SubtitleStrong = styled.strong`
  font-weight: 400;
`;

const HomeHeroCTAButton = styled.button`
  ${BaseButtonCSS};
  display: inline-block;
  height: auto;
  min-width: 200px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.accentForeground};
  color: ${(props) => props.theme.colors.accentForeground};
  padding: 10px 20px;
  background: transparent;
  font-size: 1.1em;

  margin-top: 50px;
  margin-right: 50px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    margin-top: 20px;
    margin-right: 20px;
  }

  text-align: center;
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.colors.accentForeground};
    color: ${(props) => props.theme.colors.accent};
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    font-size: 1.1em;
    min-width: auto;
  }
`;

const LandingSection = styled.section`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
`;

interface ILandingInnerProps {
  center?: boolean;
}

const LandingInner = styled.div<ILandingInnerProps>`
  width: 1200px;
  max-width: 1200px;
  padding: 200px 0;
  box-sizing: border-box;
  position: relative;

  text-align: ${(props) => (props.center ? 'center' : 'auto')};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 100%;
    padding: 60px;
  }
`;

const TravelSection = styled.section`
  width: 100%;
  max-width: 100%;
  height: 1200px;

  position: relative;

  background-color: ${(props) => props.theme.colors.accent};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    height: auto;
  }
`;

const TravelSectionBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`;

const TravelBackgroundPicture = styled.picture`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TravelBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TravelSectionInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TravelInnerCardDouble = styled.div`
  width: 1600px;
  max-width: 1600px;
  padding: 200px;
  box-sizing: border-box;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.backgroundCard};

  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 100%;
    padding: 80px;
    margin: 80px;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    margin: 50px;
    padding: 50px;
  }
`;

const CTASection = styled.div`
  background: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.accentForeground};
  width: 100%;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    height: 90vh;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    height: 50vh;
  }
`;

const Quote = styled.blockquote`
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.backgroundCard};
  margin: 0;
  padding: 50px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 50px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 20px;
  }
`;

const QuoteText = styled.div`
  font-style: italic;
  font-size: 1.2em;
`;

const QuoteAttrib = styled.div`
  font-size: 0.9em;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const Citation = styled.a`
  font-size: 11px;
  padding: 4px;
`;

const Paragraph = styled.p`
  max-width: 400px;
  line-height: 1.5em;
  margin-bottom: 30px;
`;

const BottomCTAText = styled.div`
  font-size: 1.5em;
  margin: 0;

  font-weight: 200;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    font-size: 1.3em;
  }
`;

const OuterContainer = styled.div`
  position: 'relative';
`;

const HomeHeroLoginLink = styled.a`
  color: ${(props) => props.theme.colors.accentForegroundDim};
  margin-top: 50px;
  font-size: 0.8em;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.accentForeground};
  }
`;

const HomeHeroLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TextAndScreenshot = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    flex-wrap: wrap;
  }
`;

const TextBlock = styled.div`
  margin-right: 100px;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    margin-right: 0;
  }
`;

const ScreenshotContainer = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  width: 800px;

  position: relative;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 600px;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    display: none;
  }
`;

const Screenshot = styled.img`
  width: 1000px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 100px;
  z-index: 20;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    left: 0;
    width: 600px;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    display: none;
  }
`;

const HomeHeroButtons = styled.div``;

interface IHomeProps {
  openMock: () => void;
  openDashboard: () => void;
  openRegister: () => void;
  openLogin: () => void;
  isLoggedIn: boolean;
}

const Home: React.FC<IHomeProps> = (props) => {
  const themeContext = React.useContext<typeof Theme>(ThemeContext as any);

  const openMock = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    props.openMock();
  };

  const login = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    props.openLogin();
  };

  const openDashboard = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    props.openDashboard();
  };

  const register = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    props.openRegister();
  };

  return (
    <>
      <HomeHeroContainer>
        <HomeHero>
          <HomeHeroLogoContainer>
            <HomeHeroLogoImage src={HomeHeroLogo} />
          </HomeHeroLogoContainer>
          <HomeHeroContent>
            <HomeHeroMainText>Pomelo</HomeHeroMainText>
            <HomeHeroSubtitle>
              Learn a language with the <SubtitleStrong>most effective</SubtitleStrong> tool out there.
            </HomeHeroSubtitle>
            {props.isLoggedIn && !IsMock() ? (
              <HomeHeroCTAButton as="a" href="/dashboard" rel="nofollow" onClick={openDashboard}>
                Go to Dashboard
              </HomeHeroCTAButton>
            ) : (
              <HomeHeroLinks>
                <HomeHeroButtons>
                  <HomeHeroCTAButton as="a" href="/register" onClick={register}>
                    Sign Up
                  </HomeHeroCTAButton>
                  <HomeHeroCTAButton as="a" href="/dashboard" rel="nofollow" onClick={openMock}>
                    Demo
                  </HomeHeroCTAButton>
                </HomeHeroButtons>
                <HomeHeroLoginLink onClick={login} href="/login">
                  Already have an account?
                </HomeHeroLoginLink>
              </HomeHeroLinks>
            )}
          </HomeHeroContent>
        </HomeHero>
      </HomeHeroContainer>
      <OuterContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 3" width="100%" style={{ position: 'absolute' }}>
          <polygon fill="#e2654f" points="0,0 100,0 100,3" />
        </svg>
      </OuterContainer>
      <LandingSection>
        <LandingInner>
          <TextAndScreenshot>
            <TextBlock>
              <Header size="lg">Most apps don&apos;t work.</Header>
              <Quote>
                <QuoteText>&quot;I play sports. I am eat with my friends. I am drink beer in a bar.&quot;</QuoteText>
                <QuoteAttrib>
                  &#8212;Luis von Ahn, founder of Duolingo, in French{' '}
                  <Citation
                    href="https://www.forbes.com/sites/susanadams/2019/07/16/game-of-tongues-how-duolingo-built-a-700-million-business-with-its-addictive-language-learning-app/?sh=7f337be13463"
                    target="_blank"
                    rel="noreferrer"
                  >
                    [1]
                  </Citation>
                </QuoteAttrib>
              </Quote>
              <Paragraph>Apps that claim to get you fluent &quot;quick&quot; don&apos;t work.</Paragraph>
              <Paragraph>Experienced language learners know that the most reliable way to fluency is practice.</Paragraph>
              <Paragraph>
                Pomelo takes flash cards to the next level.
                <br />
                Use AI-powered pronunciation help, powerful auto-translation tools, and cutting-edge memory research to elevate your
                language-learning journey.
              </Paragraph>
            </TextBlock>
            <ScreenshotContainer>
              <Screenshot src={Screenshot2} />
            </ScreenshotContainer>
          </TextAndScreenshot>
        </LandingInner>
      </LandingSection>
      <TravelSection>
        <TravelSectionBackground>
          <TravelBackgroundPicture>
            <TravelBackgroundImage src={TravelBackground} alt="Pomelo" />
          </TravelBackgroundPicture>
        </TravelSectionBackground>
        <OuterContainer>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 3" width="100%" style={{ position: 'absolute' }}>
            <polygon fill={themeContext.colors.background} points="0,0 100,0 100,3" />
          </svg>
        </OuterContainer>
        <TravelSectionInner>
          <TravelInnerCardDouble>
            <div>
              <Header size="lg">Cutting-edge tools.</Header>
              <Paragraph>Pomelo can help you achieve your language learning goals easier than traditional rote memorization.</Paragraph>
              <Paragraph>
                Talk to native speakers in no time. Impress friends and family, and watch people smile when they hear their mother tongue.
              </Paragraph>
            </div>
            <div>
              <Header size="md">Get started fast.</Header>
              <Paragraph>
                Import{' '}
                <a href="https://apps.ankiweb.net/" target="_blank" rel="noreferrer">
                  Anki
                </a>{' '}
                or Pomelo decks with a few clicks, or simply add new custom words to your decks in seconds.
              </Paragraph>
              <Header size="md">Learn anywhere.</Header>
              <Paragraph>Pomelo is usable on both your phone and computer &#8212; your progress is always saved.</Paragraph>
            </div>
          </TravelInnerCardDouble>
        </TravelSectionInner>
      </TravelSection>
      <LandingSection>
        <LandingInner>
          <TextAndScreenshot>
            <TextBlock>
              <Header size="lg">Backed by science.</Header>
              <Paragraph>
                Spaced-repetition apps like{' '}
                <a href="https://apps.ankiweb.net/" target="_blank" rel="noreferrer">
                  Anki
                </a>{' '}
                are based on research formulated in the 1980s as a proof-of-concept
                <Citation href="https://en.wikipedia.org/wiki/SuperMemo" target="_blank" rel="noreferrer">
                  [2]
                </Citation>
                . We use cutting-edge, science-backed spaced repetition techniques to get you speaking your target language faster.
              </Paragraph>
              <Paragraph>Using our algorithms, the language you want to learn is both locked away in memory permanently...</Paragraph>
              <Paragraph>...and ready to use at a moment&apos;s notice.</Paragraph>
            </TextBlock>
            <ScreenshotContainer>
              <Screenshot src={Screenshot1} />
            </ScreenshotContainer>
          </TextAndScreenshot>
        </LandingInner>
      </LandingSection>
      <CTASection>
        <LandingInner>
          <BottomCTAText>Sign up now for the most effective language learning memory tool out there.</BottomCTAText>
          {props.isLoggedIn && !IsMock() ? (
            <HomeHeroCTAButton as="a" href="/dashboard" rel="nofollow" onClick={props.openDashboard}>
              Go to Dashboard
            </HomeHeroCTAButton>
          ) : (
            <HomeHeroButtons>
              <HomeHeroCTAButton as="a" href="/register" onClick={props.openRegister}>
                Sign Up
              </HomeHeroCTAButton>
              <HomeHeroCTAButton as="a" rel="nofollow" href="/dashboard" onClick={openMock}>
                Demo
              </HomeHeroCTAButton>
            </HomeHeroButtons>
          )}
        </LandingInner>
      </CTASection>
    </>
  );
};

export default Home;
