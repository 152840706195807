import { create } from 'zustand';
import { ITag } from '../types/tag';

export type TISODate = string;

export interface ICard {
  id: string;
  literal: string;
  pronunciation: string;
  translation: string;
  next_showing: TISODate;
  successes: number;
  failures: number;
  notes: string;
  streak: number;
  easiness: number;
  tags: ITag[];
}

export interface IDeck {
  id: string;
  name: string;
  language: string;
  iso_2: string;
  hasPronunciation: boolean;
  locale: string;
  defaultLiteral: string;
  defaultPronunciation: string;
  romanizationMethod: string;
  hasTTS: boolean;
}

interface IDecksState {
  Decks: IDeck[];
  Cards: { [deckId: string]: ICard[] };
  SetDecks: (decks: IDeck[]) => void;
  AddDeck: (deck: IDeck) => void;
  DeleteDeck: (id: string) => void;
  SetCards: (deckId: string, cards: ICard[]) => void;
  UpdateCard: (deckId: string, cardId: string, data: Partial<ICard>) => void;
  DeleteCard: (deckId: string, cardId: string) => void;
  SetDeckName: (deckId: string, name: string) => void;
}

const useDecksState = create<IDecksState>((set) => ({
  Decks: [],
  Cards: {},
  SetDecks: (decks) => set((state) => ({ ...state, Decks: decks })),
  AddDeck: (deck) => {
    set((state) => ({
      ...state,
      Decks: [...state.Decks, deck],
      Cards: { ...state.Cards, [deck.id]: [] },
    }));
  },
  SetDeckName: (deck: string, name: string) => {
    set((state) => {
      return {
        ...state,
        Decks: state.Decks.map((v) => {
          if (v.id === deck) {
            return {
              ...v,
              name,
            };
          }

          return v;
        }),
      };
    });
  },
  SetCards: (deckId, cards) => {
    set((state) => ({ ...state, Cards: { ...state.Cards, [deckId]: cards } }));
  },
  DeleteDeck: (id) => {
    set((state) => ({
      ...state,
      Decks: state.Decks.filter((v) => v.id !== id),
      Cards: { ...state.Cards, [id]: [] },
    }));
  },
  UpdateCard: (deck, cardId, data) => {
    set((state) => ({
      ...state,
      Cards: {
        ...state.Cards,
        [deck]: state.Cards[deck].map((card) => {
          if (card.id === cardId) {
            return {
              ...card,
              ...data,
            };
          }

          return card;
        }),
      },
    }));
  },
  DeleteCard: (deck, cardId) => {
    set((state) => ({
      ...state,
      Cards: {
        ...state.Cards,
        [deck]: state.Cards[deck].filter((card) => card.id !== cardId),
      },
    }));
  },
}));

export default useDecksState;
