import { mix } from 'color2k';
import * as React from 'react';
import styled from 'styled-components';
import { ITag } from '../../types/tag';
import { Tag } from '../TagCloud';
import Markdown from 'markdown-to-jsx';
import { GetTagColor, GetTagForeground } from '../../utils/cyrb53';

interface ICardRowContainerProps {
  clickable?: boolean;
}

const CardRowContainer = styled.div<ICardRowContainerProps>`
  padding: 4px;
  box-sizing: border-box;
  border-bottom: ${(props) => props.theme.table.border};
  margin-bottom: 4px;

  display: grid;
  grid-template-columns: 3fr 6fr 3fr;
  align-items: center;

  cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};

  &:hover {
    ${(props) => (props.clickable ? `border-bottom: 1px solid ${props.theme.colors.accent};` : ``)}
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    grid-template-columns: 3fr 6fr 3fr;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    grid-template-columns: 3fr 6fr;
    font-size: 0.8em;
  }
`;

const PronunciationTranslation = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  align-items: center;
`;

interface IEasinessProps {
  perc: number;
}

const Literal = styled.div<IEasinessProps>`
  color: ${(props) => mix(props.theme.colors.error, props.theme.colors.success, props.perc)};
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    display: none;
  }
`;

const PronunciationAndNotes = styled.div`
  display: block;
`;

const NotesSummary = styled.div`
  font-size: 0.7em;
  opacity: 0.7;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
`;

interface ICardRowProps {
  literal: string;
  pronunciation: string;
  notes: string;
  hasPronunciation?: boolean;
  translation: string;
  streak: number;
  tags: ITag[];
  onEditClicked?: () => void;
  hasTTS: boolean;
}

const CardRow: React.FC<ICardRowProps> = (props) => {
  const onEditClicked = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (props.onEditClicked) {
        event.preventDefault();
        props.onEditClicked();
      }
    },
    [props.onEditClicked]
  );

  const easinessPerc = Math.min(Math.max(props.streak / 25, 0), 1);

  return (
    <CardRowContainer onClick={onEditClicked} clickable={!!props.onEditClicked}>
      <Literal perc={easinessPerc}>
        <Markdown>{props.literal}</Markdown>
      </Literal>
      <PronunciationAndNotes>
        <PronunciationTranslation>
          {props.hasPronunciation && (
            <div>
              <Markdown>{props.pronunciation || ''}</Markdown>
            </div>
          )}
          <div>
            <Markdown>{props.translation}</Markdown>
          </div>
        </PronunciationTranslation>
        <NotesSummary>
          <Markdown>{props.notes || ''}</Markdown>
        </NotesSummary>
      </PronunciationAndNotes>
      <TagList>
        {props.tags.map((tag) => (
          <Tag key={tag.id} tagColor={GetTagColor(tag.value)} tagForeground={GetTagForeground(tag.value)}>
            {tag.value}
          </Tag>
        ))}
      </TagList>
    </CardRowContainer>
  );
};

export default CardRow;
