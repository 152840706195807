import * as React from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuthState from '../../state/auth';
import useDecksState, { ICard } from '../../state/decks';
import Decks from '../../api/decks';
import Stateless from './index.stateless';

const DeckSettings = () => {
  const auth = useAuthState();
  const decks = useDecksState();
  const params = useParams<{ id: string }>();
  const id = params.id!;
  const deck = decks.Decks.find((v) => v.id === id);
  const [loading, setLoading] = React.useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();

  const [loadingDeck, setLoadingDeck] = React.useState(false);
  React.useEffect(() => {
    if (auth.User?.token && !deck) {
      setLoadingDeck(true);
      Decks.GetDecks(auth.User.token)
        .then((data) => decks.SetDecks(data))
        .finally(() => setLoadingDeck(false));
    }
  }, [auth.User?.token, deck]);

  const importCards = async (words: Partial<ICard>[]) => {
    plausible('Decks/Import');

    setLoading(true);
    const data = await Decks.ImportCards(auth.User?.token || '', id, words);

    Decks.GetCards(auth.User?.token || '', id)
      .then((data) => {
        decks.SetCards(id, data);
      })
      .finally(() => setLoading(false));

    return data;
  };

  const deleteDeck = async () => {
    if (auth.User?.token) {
      plausible('Decks/Delete');

      Decks.DeleteDeck(auth.User.token, id).then(() => {
        decks.DeleteDeck(id);
        navigate(`/dashboard${search}`);
      });
    }
  };

  const navigateDeck = () => navigate(`/dashboard/decks/${deck!.id}`);
  const navigateAnki = () => navigate(`/dashboard/decks/${deck!.id}/anki`);
  const navigateQuizlet = () => navigate(`/dashboard/decks/${deck!.id}/quizlet`);

  return (
    <Stateless
      deck={deck}
      cards={decks.Cards[id]}
      loading={loading || loadingDeck}
      navigateDeck={navigateDeck}
      navigateAnki={navigateAnki}
      navigateQuizlet={navigateQuizlet}
      importCards={importCards}
      deleteDeck={deleteDeck}
    />
  );
};

export default DeckSettings;
