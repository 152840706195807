import * as React from 'react';
import { IDeck } from '../../../state/decks';
import Loader from '../../../components/Loader';
import PageContainer from '../../../components/PageContainer';
import Center from '../../../components/Center';
import Header from '../../../components/Header';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from '../../../components/ButtonIcon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import styled from 'styled-components';

const Container = styled.div`
  width: 1200px;
  max-width: 1200px;
  padding: 50px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 20px;
  }
`;

const SettingsBacklink = styled.a`
  font-size: 0.8em;
  margin-top: 10px;
  margin-bottom: 50px;
  display: block;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  top: -9999px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 50px;
`;

interface IQuizletImportProps {
  deck: IDeck | undefined;
  loading: boolean;
  navigateSettings: () => void;
  onFileSelected: (file: File) => Promise<void>;
}

const QuizletImport = (props: IQuizletImportProps) => {
  const importInputRef = React.useRef<HTMLInputElement>(null);
  const startImport = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (importInputRef.current) {
      importInputRef.current.click();
    }
  }, []);

  const [importing, setImporting] = React.useState(false);
  const onImportChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : undefined;
    if (!file) {
      return;
    }

    props.onFileSelected(file);
  };

  return props.loading ? (
    <Loader />
  ) : (
    <PageContainer>
      <Center>
        <Container>
          {props.deck ? (
            <>
              <HiddenFileInput type="file" accept=".txt" ref={importInputRef} onChange={onImportChanged} />
              <Header size="lg" nomargin>
                Import Quizlet Deck
              </Header>
              <SettingsBacklink onClick={props.navigateSettings}>Back to {props.deck.name} settings</SettingsBacklink>
              <Card>
                <ButtonsContainer>
                  <Button onClick={startImport} disabled={importing}>
                    <ButtonIcon icon={faFileImport} /> Select Quizlet Deck
                  </Button>
                </ButtonsContainer>
              </Card>
            </>
          ) : (
            <div>No deck found.</div>
          )}
        </Container>
      </Center>
    </PageContainer>
  );
};

export default QuizletImport;
