import * as React from 'react';
import styled from 'styled-components';
import { ICard, IDeck } from '../../state/decks';
import Papa from 'papaparse';
import toast from 'react-hot-toast';
import PageContainer from '../../components/PageContainer';
import IconButton from '../../components/IconButton';
import { faFileExport, faFileImport, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import Center from '../../components/Center';
import Loader from '../../components/Loader';
import ConfirmModal from '../../components/ConfirmModal';
import TooltipWrapper from '../../components/TooltipWrapper';
import Card from '../../components/Card';
import { IImportCardsResponse } from '../../types/decks';

const Container = styled.div`
  width: 1200px;
  max-width: 1200px;
  padding: 50px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 20px;
  }
`;

const SettingsBacklink = styled.a`
  font-size: 0.8em;
  margin-top: 10px;
  margin-bottom: 50px;
  display: block;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  top: -9999px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 50px;
`;

const ButtonSpacer = styled.div`
  margin-right: 10px;
`;

interface ICardExport {
  Literal: string;
  Pronunciation: string;
  Translation: string;
  'Next Showing': string;
  Successes: string;
  Failures: string;
  Notes: string;
  Streak: string;
  Easiness: string;
  Tags: string;
}

interface IDeckSettings {
  deck: IDeck | undefined;
  cards: ICard[];
  loading: boolean;
  navigateDeck: () => void;
  navigateAnki: () => void;
  navigateQuizlet: () => void;
  importCards: (words: Partial<ICard>[]) => Promise<IImportCardsResponse>;
  deleteDeck: () => Promise<void>;
}

const DeckSettings = (props: IDeckSettings) => {
  const importInputRef = React.useRef<HTMLInputElement>(null);
  const startImport = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (importInputRef.current) {
      importInputRef.current.click();
    }
  }, []);

  const [importing, setImporting] = React.useState(false);
  const onImportChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : undefined;
    if (!file) {
      return;
    }

    const data = await new Promise<ICardExport[]>((res, rej) => {
      Papa.parse<ICardExport>(file, {
        header: true,
        complete: (results) => {
          if (results.errors.length) {
            rej(results.errors);
          } else {
            res(results.data);
          }
        },
      });
    });

    const words: Partial<ICard>[] = data.map((row) => ({
      literal: row.Literal,
      pronunciation: props.deck?.hasPronunciation ? row.Pronunciation : '',
      translation: row.Translation,
      next_showing: row['Next Showing'] || new Date().toISOString(),
      successes: parseInt(row.Successes, 10) || 0,
      failures: parseInt(row.Failures, 10) || 0,
      notes: row.Notes,
      streak: parseInt(row.Streak, 10) || 0,
      sm2_easiness_factor: parseInt(row.Easiness, 10) || 2.5,
      tags: row.Tags.length > 0 ? row.Tags.split(',').map((v) => ({ id: (Math.random() * 1000000).toString(), value: v })) : [],
    }));

    event.target.value = '';

    setImporting(true);
    const promise = props.importCards(words);
    await toast.promise(
      promise,
      {
        loading: 'Importing...',
        success: (data) => `Updated ${data.updated} and created ${data.new} cards.`,
        error: 'There was an error importing your cards.',
      },
      { duration: 5000 }
    );
    setImporting(false);
  };

  const onExportClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const csvObject: { fields: string[]; data: string[][] } = {
      fields: [
        'Literal',
        ...(props.deck?.hasPronunciation ? ['Pronunciation'] : []),
        'Translation',
        'Next Showing',
        'Successes',
        'Failures',
        'Notes',
        'Streak',
        'Easiness',
        'Tags',
      ],
      data: props.cards.map((card) => [
        card.literal,
        ...(props.deck?.hasPronunciation ? [card.pronunciation] : []),
        card.translation,
        card.next_showing,
        card.successes.toString(),
        card.failures.toString(),
        card.notes,
        card.streak.toString(),
        card.easiness.toString(),
        card.tags.map((t) => t.value).join(','),
      ]),
    };

    const str = Papa.unparse(csvObject, { header: true });

    plausible('Decks/Export');

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(str));
    element.setAttribute('download', 'pomelo.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const importAnki = () => props.navigateAnki();
  const importQuizlet = () => props.navigateQuizlet();

  const [deletingDeckModalOpen, setDeletingDeckModalOpen] = React.useState(false);
  const deleteDeckConfirm = React.useCallback(() => {
    setDeletingDeckModalOpen(true);
  }, []);
  const cancelDeleteConfirm = React.useCallback(() => {
    setDeletingDeckModalOpen(false);
  }, []);

  const [deletingDeck, setDeletingDeck] = React.useState(false);
  const deleteDeck = () => {
    setDeletingDeckModalOpen(false);
    setDeletingDeck(true);
    props.deleteDeck();
  };

  return props.loading ? (
    <Loader />
  ) : (
    <PageContainer>
      <Center>
        <Container>
          {props.deck ? (
            <>
              <HiddenFileInput type="file" accept=".csv" ref={importInputRef} onChange={onImportChanged} />
              <Header size="lg" nomargin>
                Deck Settings
              </Header>
              <SettingsBacklink onClick={props.navigateDeck}>Back to {props.deck.name}</SettingsBacklink>
              <Card>
                <Header size="md">Importing/Exporting</Header>
                <ButtonsContainer>
                  <ButtonSpacer>
                    <TooltipWrapper text="Import">
                      <IconButton icon={faFileImport} onClick={startImport} disabled={importing} />
                    </TooltipWrapper>
                  </ButtonSpacer>
                  <ButtonSpacer>
                    <TooltipWrapper text="Export">
                      <IconButton icon={faFileExport} onClick={onExportClicked} disabled={importing} />
                    </TooltipWrapper>
                  </ButtonSpacer>
                  <ButtonSpacer>
                    <TooltipWrapper text="Import Anki">
                      <IconButton icon={faStar} onClick={importAnki} disabled={importing} />
                    </TooltipWrapper>
                  </ButtonSpacer>
                  <ButtonSpacer>
                    <TooltipWrapper text="Import Quizlet">
                      <IconButton icon={faStar} onClick={importQuizlet} disabled={importing} />
                    </TooltipWrapper>
                  </ButtonSpacer>
                </ButtonsContainer>
                <Header size="md">Delete Deck</Header>
                <ButtonsContainer>
                  <ButtonSpacer>
                    {deletingDeck ? (
                      <Loader />
                    ) : (
                      <TooltipWrapper text="Delete">
                        <IconButton type="button" icon={faTrash} danger onClick={(evt) => deleteDeckConfirm()} />
                      </TooltipWrapper>
                    )}
                  </ButtonSpacer>
                </ButtonsContainer>
              </Card>
              {deletingDeckModalOpen && (
                <ConfirmModal
                  title="Confirm"
                  message="Are you sure you want to delete this deck? It will be permanently removed, along with the cards it contains."
                  onConfirm={deleteDeck}
                  onReject={cancelDeleteConfirm}
                />
              )}
            </>
          ) : (
            <div>No deck found.</div>
          )}
        </Container>
      </Center>
    </PageContainer>
  );
};

export default DeckSettings;
