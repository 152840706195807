import React from 'react';
import styled, { css } from 'styled-components';

export interface IInputProps {
  block?: boolean;
  maxWidth?: number;
}

export interface ISelectProps extends IInputProps, React.InputHTMLAttributes<HTMLInputElement> {}

export const InputBase = css<IInputProps>`
  box-sizing: border-box;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.border};

  background-color: ${(props) => props.theme.colors.backgroundInput};

  min-width: 250px;
  width: ${(props) => (props.block ? '100%' : 'auto')};
  max-width: ${(props) => (props.maxWidth !== null ? props.maxWidth + 'px' : 'initial')};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    min-width: 100px;
  }

  display: ${(props) => (props.block ? 'block' : 'inline-block')};

  &:active,
  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    border: 1px solid ${(props) => props.theme.colors.borderHighlight};
  }

  &:focus {
    background: ${(props) => props.theme.colors.backgroundHighlight};
    border: 1px solid ${(props) => props.theme.colors.accent};
  }
`;

const Input = styled.input<IInputProps>`
  ${InputBase}
`;

export default Input;

export const TextInputWithButton = styled.div`
  position: relative;
`;

export const TextInputButtonContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 0px;
  bottom: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

export const TextArea = styled.textarea<IInputProps>`
  ${InputBase}
  min-height: 100px;
  padding: 8px;
  border-radius: 4px;
  max-width: ${(props) => (props.maxWidth !== null ? props.maxWidth + 'px' : '100%')};
`;
